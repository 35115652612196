<script lang="ts" setup>
const {$pwa} = useNuxtApp();
if ($pwa && !$pwa.isPWAInstalled) {
  $pwa.showInstallPrompt = true
}

definePageMeta({
  layout: 'empty',
  isPublic: true,
})
</script>

<template>
  <div class="row">
    <div class="container">
      <h1 class="title">PWA Debug Page</h1>
      <ClientOnly>
        <pre>{{ $pwa }}</pre>
      </ClientOnly>
    </div>
    <NuxtLink to="/home" :external="true">Home</NuxtLink>
  </div>
</template>
